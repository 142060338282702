import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import Spinner from "../../components/preLoaders/Spinner";
import {
  useCreateTipsMutation,
  useReturnAllTipsQuery,
} from "../../Slices/apisSlice";
import PageLoader from "../../components/preLoaders/PageLoader";
import FormattedDate from "../../components/FormattedDate";
import axios from "axios";

function Index() {
  const [games, setGames] = useState([]);
  const { data, isLoading, refetch, isError } = useReturnAllTipsQuery();
  const [oddModal, setOddModal] = useState(false);
  const [today, setToday] = useState(new Date());
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const [id, setId] = useState(false);
  const [openTab, setOpenTab] = useState(1);
  useEffect(() => {
    if (data) {
      setGames(data.bet_tips.data);
    }
  }, [data]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setToday(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;

  const { register, handleSubmit, reset } = useForm();
  const [createTips, { isLoading: loading }] = useCreateTipsMutation();
  const setCreateHandler = async (data) => {
    try {
      await createTips(data);
      toast.success("Successful");
      setOddModal(false);
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const updateResultHandler = async (data) => {
    try {
      setLoad(true);
      const res = await axios.put(`${BASE_URL}updateResults/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      const message = res.data.message;
      setLoad(false);
      toast.success(message);
      setUpdate(false);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  const deleteHandler = async (data) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}betTips/${data}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      toast.success(message);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };

  return (
    <div className="p-3 lg:p-10 max-w-7xl">
      <div className="">
        <div className="flex justify-between">
          <div className="space-y-3">
            <h2 className="text-[#022045]   text-4xl font-semibold text-left   ">
              Welcome, Admin!!
            </h2>
            <div class="font-light text-[#64748B] text-xl pt-2 ">
              {today.toDateString()}
            </div>
          </div>
          <button
            onClick={(e) => {
              e.preventDefault();
              setOddModal(true);
            }}
            className="flex transition-colors duration-200 h-12   hover:text-[#fc8] focus:outline-none text-[#fff]  bg-[#FF4B00] px-3 py-1 rounded-md gap-x-1 items-center "
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 3.33331V12.6666"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.33325 8H12.6666"
                stroke="CurrentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Add Tips</span>
          </button>
        </div>

        <div className="container w-[31rem] text-gray-600 my-10 bg-white  rounded-2xl">
          <div class="flex justify-between  mt-8 lg:mt-10 ">
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              className={`   ${
                openTab === 1
                  ? "border-b-4 border-[#FF4B00] font-extrabold text-gray-900 flex items-center justify-between "
                  : ""
              } transition-colors duration-200    hover:bg-gray-100`}
            >
              <div className="p-6 flex gap-x-3 items-center justify-between">
                <img src="../tag.svg" alt="" />
                <h1 className="">Free Daily Bet Tips</h1>
              </div>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              className={`   ${
                openTab === 2
                  ? "border-b-4 border-[#FF4B00] font-extrabold text-gray-900 flex items-center justify-between "
                  : ""
              } transition-colors duration-200    hover:bg-gray-100`}
            >
              <div className="p-6 flex gap-x-3 items-center justify-between">
                <img src="../crown.svg" alt="" />
                <h1 className="mt">Combo VIP Tickets</h1>
              </div>
            </button>
          </div>
        </div>
      </div>

      {/* +++++++++++++++++++++++Free Fixed odd +++++++++++++++++++++++++++++ */}
      <div className={openTab === 1 ? "block" : "hidden"}>
        {isLoading ? (
          <div className="pb-5">
            <PageLoader />
          </div>
        ) : isError ? (
          <div className="pt-5">
            <PageLoader />
          </div>
        ) : (
          <div className="grid gap-5 grid-cols-1 md:grid-cols-3 overflow-x-auto rounded-lg h-[30rem] scrollbar-hide">
            {games
              .filter((item) => item.status === "is_free")
              .map((item, index) => (
                <div
                  key={index}
                  className="space-y-2 bg-white text-sm p-5 rounded-xl"
                >
                  <div className="flex items-end justify-end">
                    <button
                      onClick={() => {
                        setId(item.id);
                        setUpdate(true);
                      }}
                      className="text-center rounded-lg  text-gray-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4  mt-0.5"
                      >
                        <path
                          d="M16.1714 1.91436C16.434 1.65172 16.7458 1.44338 17.089 1.30124C17.4322 1.1591 17.8 1.08594 18.1714 1.08594C18.5428 1.08594 18.9106 1.1591 19.2538 1.30124C19.5969 1.44338 19.9087 1.65172 20.1714 1.91436C20.434 2.17701 20.6424 2.48881 20.7845 2.83197C20.9267 3.17513 20.9998 3.54293 20.9998 3.91436C20.9998 4.2858 20.9267 4.6536 20.7845 4.99676C20.6424 5.33992 20.434 5.65172 20.1714 5.91436L6.67139 19.4144L1.17139 20.9144L2.67139 15.4144L16.1714 1.91436Z"
                          stroke="CurrentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        deleteHandler(item.id);
                      }}
                      className="text-center rounded-lg  text-red-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-3  sm:w-5 sm:h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2 border-b pb-2">
                    <label
                      htmlFor="league_name"
                      className="text-gray-500 text-left"
                    >
                      # {index + 1}
                    </label>
                    <p className="text-gray-900 font-bold">
                      <FormattedDate date={item?.created_at} />
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="league_name"
                      className="text-gray-500 text-left"
                    >
                      League Name
                    </label>
                    <p className="text-gray-700">{item.league_name}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label htmlFor="data" className="text-gray-500 text-left">
                      Date
                    </label>
                    <p className="text-gray-700">{item.date}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Kick off"
                      className="text-gray-500 text-left"
                    >
                      Kick off
                    </label>
                    <p className="text-gray-700">{item.kickoff_time}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="away_team"
                      className="text-gray-500 text-left"
                    >
                      Away Team
                    </label>
                    <p className="text-gray-700">{item.away_team}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="home_team"
                      className="text-gray-500 text-left"
                    >
                      Home Team
                    </label>
                    <p className="text-gray-700">{item.home_team}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Odds
                    </label>
                    <p className="text-gray-700">{item.odd}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Tips
                    </label>
                    <p className="text-gray-700">{item.tip}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Status
                    </label>
                    <p className="text-gray-700">{item.status}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Result
                    </label>
                    <p className="text-gray-700">{item.results}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Remark
                    </label>
                    <p className="text-gray-700">{item.remark}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      {/* +++++++++++++++++++++++Combo Fixed odd +++++++++++++++++++++++++++++ */}
      <div className={openTab === 2 ? "block" : "hidden"}>
        {isLoading ? (
          <div className="pb-5">
            <PageLoader />
          </div>
        ) : isError ? (
          <div className="pt-5">
            <PageLoader />
          </div>
        ) : (
          <div className="grid gap-5 grid-cols-1 md:grid-cols-3 overflow-x-auto rounded-lg h-[30rem] scrollbar-hide">
            {games
              .filter((item) => item.status === "is_paid")
              .map((item, index) => (
                <div
                  key={index}
                  className="space-y-2 bg-white text-sm p-5 rounded-xl"
                >
                  <div className="flex items-end justify-end">
                    <button
                      onClick={() => {
                        setId(item.id);
                        setUpdate(true);
                      }}
                      className="text-center rounded-lg  text-gray-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4  mt-0.5"
                      >
                        <path
                          d="M16.1714 1.91436C16.434 1.65172 16.7458 1.44338 17.089 1.30124C17.4322 1.1591 17.8 1.08594 18.1714 1.08594C18.5428 1.08594 18.9106 1.1591 19.2538 1.30124C19.5969 1.44338 19.9087 1.65172 20.1714 1.91436C20.434 2.17701 20.6424 2.48881 20.7845 2.83197C20.9267 3.17513 20.9998 3.54293 20.9998 3.91436C20.9998 4.2858 20.9267 4.6536 20.7845 4.99676C20.6424 5.33992 20.434 5.65172 20.1714 5.91436L6.67139 19.4144L1.17139 20.9144L2.67139 15.4144L16.1714 1.91436Z"
                          stroke="CurrentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <button
                      onClick={() => {
                        deleteHandler(item.id);
                      }}
                      className="text-center rounded-lg  text-red-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-3  sm:w-5 sm:h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2 border-b pb-2">
                    <label
                      htmlFor="league_name"
                      className="text-gray-500 text-left"
                    >
                      # {index + 1}
                    </label>
                    <p className="text-gray-900 font-bold">
                      <FormattedDate date={item?.created_at} />
                    </p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="league_name"
                      className="text-gray-500 text-left"
                    >
                      League Name
                    </label>
                    <p className="text-gray-700">{item.league_name}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label htmlFor="data" className="text-gray-500 text-left">
                      Date
                    </label>
                    <p className="text-gray-700">{item.date}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Kick off"
                      className="text-gray-500 text-left"
                    >
                      Kick off
                    </label>
                    <p className="text-gray-700">{item.kickoff_time}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="away_team"
                      className="text-gray-500 text-left"
                    >
                      Away Team
                    </label>
                    <p className="text-gray-700">{item.away_team}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="home_team"
                      className="text-gray-500 text-left"
                    >
                      Home Team
                    </label>
                    <p className="text-gray-700">{item.home_team}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Odds
                    </label>
                    <p className="text-gray-700">{item.odd}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Tips
                    </label>
                    <p className="text-gray-700">{item.tip}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Status
                    </label>
                    <p className="text-gray-700">{item.status}</p>
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Result
                    </label>
                    <p className="text-gray-700">{item.results}</p>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Remark
                    </label>
                    <p className="text-gray-700">{item.remark}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>

      {/* +++++++++++++++++++++++Week Fixed odd +++++++++++++++++++++++++++++ */}
      {oddModal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-xl sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <h3 className="text-[#000] text-lg font-semibold">
                  Add New Tip
                </h3>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOddModal(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(setCreateHandler)(e);
                  reset();
                }}
                className="  transition-all duration-500"
              >
                <div className="space-y-4 ">
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="league_name"
                      className="text-gray-500 text-left"
                    >
                      League Name
                    </label>
                    <input
                      type="text"
                      required
                      {...register("league_name")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label htmlFor="data" className="text-gray-500 text-left">
                      Date
                    </label>
                    <input
                      type="date"
                      required
                      {...register("date")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Kick off"
                      className="text-gray-500 text-left"
                    >
                      Kick off
                    </label>
                    <input
                      type="time"
                      required
                      {...register("kickoff_time")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Teams
                    </label>
                    <input
                      type="text"
                      required
                      {...register("teams")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="away_team"
                      className="text-gray-500 text-left"
                    >
                      Away Team
                    </label>
                    <input
                      type="text"
                      required
                      {...register("away_team")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="home_team"
                      className="text-gray-500 text-left"
                    >
                      Home Team
                    </label>
                    <input
                      type="text"
                      required
                      {...register("home_team")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Odds
                    </label>
                    <input
                      type="text"
                      required
                      {...register("odd")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Tips
                    </label>
                    <input
                      type="text"
                      required
                      {...register("tip")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>

                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Status
                    </label>
                    <select
                      {...register("status")}
                      required
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    >
                      <option></option>
                      <option value="is_paid">Paid</option>
                      <option value="is_free">Free</option>
                    </select>
                  </div>
                </div>
                <button
                  type="submit"
                  className="mx-4 mt-4 font-semibold w-[14rem] h-[3rem] mb-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#FF4B00]  px-3 py-2.5 rounded-lg "
                >
                  {loading ? <Spinner /> : "Create"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}

      {/* +++++++++++++++++++++++Week Fixed odd +++++++++++++++++++++++++++++ */}
      {update && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center min-h-screen px-4 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-3 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle ">
              <div className="flex items-start justify-between ">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setUpdate(false);
                  }}
                  className="flex items-center gap-x-1"
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6667 8H20.6667C23.9805 8 26.6667 10.6863 26.6667 14C26.6667 17.3137 23.9805 20 20.6667 20H6.66675"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.33323 24L6.27604 20.9428C5.83159 20.4984 5.60938 20.2761 5.60938 20C5.60938 19.7239 5.83159 19.5016 6.27604 19.0572L9.33323 16"
                      stroke="#0C0C0C"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel
                </button>
              </div>
              <div className="py-2"></div>

              <h3 className="text-[#000] text-lg font-semibold pb-3">
                Update Odd
              </h3>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(updateResultHandler)(e);
                  reset();
                }}
                className="  transition-all duration-500"
              >
                <div className="space-y-4 ">
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Tips
                    </label>
                    <input
                      type="text"
                      required
                      {...register("tip")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Results
                    </label>
                    <select
                      {...register("results")}
                      required
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-3 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    >
                      <option>Update Result</option>
                      <option value="won">Won</option>
                      <option value="loss">Lost</option>
                    </select>
                  </div>
                  <div className="w-full flex justify-between items-center gap-x-2">
                    <label
                      htmlFor="Denomination"
                      className="text-gray-500 text-left"
                    >
                      Remark
                    </label>
                    <textarea
                      type="text"
                      required
                      rows={3}
                      {...register("remark")}
                      className="block text-xs mt-1 w-[20rem] placeholder-gray-400/70 rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-gray-700 focus:border-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="mx-4 mt-4 font-semibold w-[14rem] h-[3rem] mb-5 transition-colors duration-200 text-sm hover:text-gray-300 focus:outline-none text-[#fff] bg-[#FF4B00]  px-3 py-2.5 rounded-lg "
                >
                  {load ? <Spinner /> : "Update"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Index;
