import { apiOne, apiTwo } from "./apiSlice";

export const allApiSlice = apiOne.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: `admin/loginAdmin`,
        method: "POST",
        body: data,
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `auth/password_reset`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginMutation, useForgotPasswordMutation } = allApiSlice;

export const authApiSlice = apiTwo.injectEndpoints({
  endpoints: (builder) => ({
    validateCode: builder.mutation({
      query: (data) => ({
        url: `validateCode`,
        method: "POST",
        body: data,
      }),
    }),
    generateWeeklyCode: builder.query({
      query: () => ({
        url: `generateWeeklyCode`,
        keepUnusedDataFor: 5,
      }),
    }),

    generateMonthlyCode: builder.query({
      query: () => ({
        url: `generateMonthlyCode`,
        keepUnusedDataFor: 5,
      }),
    }),

    getCodes: builder.query({
      query: () => ({
        url: `getCodes`,
        keepUnusedDataFor: 5,
      }),
    }),

    returnAllTips: builder.query({
      query: () => ({
        url: `betTips`,
        keepUnusedDataFor: 5,
      }),
    }),

    createTips: builder.mutation({
      query: (data) => ({
        url: `betTips`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useCreateTipsMutation,
  useGenerateMonthlyCodeQuery,
  useGenerateWeeklyCodeQuery,
  useReturnAllTipsQuery,
  useValidateCodeMutation,
  useGetCodesQuery,
} = authApiSlice;
