import React, { useEffect, useState } from "react";
import { useGetCodesQuery } from "../../Slices/apisSlice";
import PageLoader from "../../components/preLoaders/PageLoader";
import FormattedDate from "../../components/FormattedDate";
import { BASE_URL } from "../../utils";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

function Generate() {
  const { userInfo } = useSelector((state) => state.auth);
  const token = userInfo.access_token;
  const [codes, setCodes] = useState([]);
  const [odd, setOdd] = useState("");
  const [display, setDisplay] = useState("");
  const { data, isLoading, refetch, isError } = useGetCodesQuery();
  const [openTab, setOpenTab] = useState(1);
  useEffect(() => {
    if (data) {
      setCodes(data.bet_tips.data);
    }
  }, [data]);

  const weeklyHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}generateWeeklyCode`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      const response = res.data.message.code;
      setDisplay(response);
      toast.success("Successful");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const monthlyHandler = async () => {
    try {
      const res = await axios.get(`${BASE_URL}generateMonthlyCode`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          accept: "application/json",
        },
      });

      const response = res.data.message.code;
      setDisplay(response);
      toast.success("Successful");
      refetch();
    } catch (err) {
      toast.error(err?.data?.message || err?.message);
    }
  };

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(display)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
      })
      .catch((error) => {
        console.error("Failed to copy text:", error);
      });
  };

  const deleteHandler = async (data) => {
    try {
      const res = await axios.delete(
        `${BASE_URL}deleteGeneratedCode/${data}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            accept: "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      const message = res.data.message;
      toast.success(message);
      refetch();
    } catch (err) {
      toast.error(err?.message || err?.data.message);
    }
  };
  return (
    <section className="container p-10  mx-auto">
      <div className="w-full flex bg-[#fff] items-center justify-between gap-x-5 rounded-lg p-9">
        <div className="relative">
          <select
            onChange={(e) => setOdd(e.target.value)}
            required
            className="py-3 block appearance-none border bg-white rounded-xl w-[14rem] focus:outline-none px-5"
          >
            <option value="weekly">Weekly Code</option>
            <option value="monthly">Monthly Code</option>
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 14C18 14 13.5811 19 12 19C10.4188 19 6 14 6 14"
                stroke="#8F8F8F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M18 9.99996C18 9.99996 13.5811 5.00001 12 5C10.4188 4.99999 6 10 6 10"
                stroke="#8F8F8F"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div className="bg-white text-gray-900 text-sm rounded-xl focus:outline-none block w-full pl-9 p-3 placeholder-bold border">
          {display}
        </div>

        <div className="w-full flex items-center gap-x-5">
          {display ? (
            <button
              onClick={handleCopy}
              className="bg-[#8F8F8F] flex items-center  text-white py-2 px-6 rounded-lg"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0934 2.16675H10.2396C8.1777 2.16675 6.49984 3.84461 6.49984 5.90652V6.50008H5.90628C3.84437 6.50008 2.1665 8.17795 2.1665 10.2399V20.0935C2.1665 22.1555 3.84437 23.8334 5.90628 23.8334H15.76C17.6542 23.8334 19.2073 22.4123 19.4502 20.5834H20.0933C22.1553 20.5834 23.8332 18.9055 23.8332 16.8436V5.90652C23.8332 3.84461 22.1553 2.16675 20.0934 2.16675ZM21.6665 16.8436C21.6665 17.7111 20.9608 18.4167 20.0934 18.4167H19.4998V10.2399C19.4998 8.17795 17.822 6.50008 15.7601 6.50008H8.6665V5.90652C8.6665 5.0391 9.37219 4.33341 10.2396 4.33341H20.0933C20.9608 4.33341 21.6665 5.0391 21.6665 5.90652V16.8436Z"
                  fill="white"
                />
              </svg>
              {isCopied ? "Copied!" : "Copy"}
            </button>
          ) : (
            <button
              onClick={() => {
                if (odd === "monthly") {
                  monthlyHandler();
                } else {
                  weeklyHandler();
                }
              }}
              className="w-full bg-[#FF4B00] text-white text-sm p-3 rounded-lg"
            >
              Generate Access Code
            </button>
          )}
        </div>
      </div>

      <div className="container w-[31rem] text-gray-600 my-10 bg-white  rounded-2xl">
        <div class="flex justify-between  mt-8 lg:mt-10 ">
          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(1);
            }}
            className={`   ${
              openTab === 1
                ? "border-b-4 border-[#FF4B00] font-extrabold text-gray-900 flex items-center justify-between "
                : ""
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <div className="p-6 flex gap-x-3 items-center justify-between">
              <img src="../calendar.svg" alt="" />
              <h1 className="mt">Week Fixed odd</h1>
            </div>
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              setOpenTab(2);
            }}
            className={`   ${
              openTab === 2
                ? "border-b-4 border-[#FF4B00] font-extrabold text-gray-900 flex items-center justify-between "
                : ""
            } transition-colors duration-200    hover:bg-gray-100`}
          >
            <div className="p-6 flex gap-x-3 items-center justify-between">
              <img src="../notebook.svg" alt="" />
              <h1 className="mt">Monthly Plans</h1>
            </div>
          </button>
        </div>
      </div>

      {/* Table+++++++++++++++++++++++++++++++ */}

      <div className="flex flex-col my-8 bg-[#fff]">
        <div className="overflow-x-auto rounded-lg">
          <div className="align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden sm:rounded-lg">
              {/* +++++++++++++++++++++++Weekly Code +++++++++++++++++++++++++++++ */}
              <div className={openTab === 1 ? "block" : "hidden"}>
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoader />
                  </div>
                ) : isError ? (
                  <div className="pt-5">
                    <PageLoader />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-[#fff]">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          CODE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          Expiry DATE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          TYPE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          CREATED AT
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-[#fff]">
                      {codes
                        .filter((item) => item.type === "weekly")
                        .map((item, i) => (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "" : "bg-gray-50"}
                          >
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                              {i + 1}
                            </td>
                            <td className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left flex gap-x-2">
                              <span className="font-semibold">{item.code}</span>
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <FormattedDate date={item?.expires_at} />
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-500">
                              {item.type}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <FormattedDate date={item?.created_at} />
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <button
                                onClick={() => {
                                  deleteHandler(item.id);
                                }}
                                className="text-center rounded-lg  text-red-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-3  sm:w-5 sm:h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>

              {/* +++++++++++++++++++++++Monthly Code +++++++++++++++++++++++++++++ */}
              <div className={openTab === 2 ? "block" : "hidden"}>
                {isLoading ? (
                  <div className="pb-5">
                    <PageLoader />
                  </div>
                ) : isError ? (
                  <div className="pt-5">
                    <PageLoader />
                  </div>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-[#fff]">
                      <tr>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          CODE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          Expiry DATE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          TYPE
                        </th>
                        <th
                          scope="col"
                          className="p-4 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          CREATED AT
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-[#fff]">
                      {codes
                        .filter((item) => item.type === "monthly")
                        .map((item, i) => (
                          <tr
                            key={i}
                            className={i % 2 === 0 ? "" : "bg-gray-50"}
                          >
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                              {i + 1}
                            </td>
                            <td className="cursor-pointer p-4 whitespace-nowrap text-sm font-normal text-gray-900 rounded-lg rounded-left flex gap-x-2">
                              <span className="font-semibold">{item.code}</span>
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <FormattedDate date={item?.expires_at} />
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-semibold text-gray-500">
                              {item.type}
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <FormattedDate date={item?.created_at} />
                            </td>
                            <td className="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                              <button
                                onClick={() => {
                                  deleteHandler(item.id);
                                }}
                                className="text-center rounded-lg  text-red-700 font-bold text-xs cursor-pointer hover:bg-gray-200 p-2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="w-3  sm:w-5 sm:h-5"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Generate;
