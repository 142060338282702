import React from "react";
import { Link } from "react-router-dom";

function UserDetails() {
  return (
    <section className="container p-10  mx-auto">
      <div className="flex flex-col bg-[#F9F9F9]  rounded-lg">
        <div className=" overflow-x-auto  h-[36rem] scrollbar-hide bg-[#FFF] rounded-2xl">
          <div className="inline-block min-w-full p-9 align-middle ">
            <div className="overflow-hidden md:rounded-lg ">
              <div className="min-w-full ">
                {/* {isLoading ? (
                    <div className="pb-5">
                      <PageLoading />
                    </div>
                  ) : error ? (
                    <div className="pt-5">
                      <Loading />
                    </div>
                  ) : ( */}
                <>
                  {/* {filteredAccounts.map((item) => ( */}
                  <div
                    // key={item.id}
                    className="my-2 rounded-lg"
                  >
                    <div className="flex gap-x-5">
                      <img src="../avatar.svg" alt="" />

                      <div className="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
                        <h1> full_name</h1>
                        <p className="text-[#B0B7C3] font-normal">
                          joined 2 days ago
                        </p>
                      </div>
                    </div>
                    <div className="space-y-5">
                      <div className="flex  items-center justify-between  px-4 py-2 rounded-lg  flex gap-x-10 text-sm text-[#4D98D2] font-medium w-full">
                        <div className="block w-full">
                          <p className="p-3 text-[#000]">Full Name</p>
                          <p className="bg-[#F9F9F9] p-3  text-[#000000]">
                            Your First Name
                          </p>
                        </div>
                        <div className="block w-full">
                          <p className="p-3 text-[#000]">Full Name</p>
                          <p className="bg-[#F9F9F9] p-3 text-[#000000]">
                            Email Address
                          </p>
                        </div>
                      </div>
                      <div className="p-3 text-sm text-[#fff] whitespace-nowrap ">
                        <button
                          // to={`${item.id}/details`}
                          className="bg-[#FF4B00] py-2 px-8 rounded-lg"
                        >
                          Delete User
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* ))} */}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserDetails;
