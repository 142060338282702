import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SEO from "../../components/SEO";
import { useForgotPasswordMutation } from "../../Slices/apisSlice";
import Spinner from "../../components/preLoaders/Spinner";

function ForgotPassword() {
  const [modal, setModal] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const navigate = useNavigate();

  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const { register, handleSubmit } = useForm();

  const forgotPasswordHandler = async (data) => {
    try {
      const res = await forgotPassword(data);
      toast.success(res?.data?.msg);
      if (res.status === 200) {
        setModal(true);
      } else {
        toast.error(res?.error.data?.msg);
      }
    } catch (err) {
      toast.error(err?.data?.msg || err.error);
    }
  };

  return (
    <>
      <SEO
        title="Forget Password"
        description="EnterpriseBuzz is a pioneering media and technology company, dedicated to propelling businesses forward through innovative advertising, branding, CRM, and marketing tools."
        name="EnterpriceBuzz"
        type="description"
      />
      <div class="lg:grid grid-cols-12 gap-x-5 font-Lato">
        <div
          class="col-span-5 hidden bg-cover lg:block pt-40"
          style={{
            "background-image": "url('../bg.svg')",
          }}
        >
          <div className="w-[19.5rem] mx-auto text-center flex flex-col align-middle justify-center py-40">
            <img src="./logo.svg" className="w-full" alt="i" />
            <h3
              className="text-base lg:text-xl  font-extralight  py-5 leading-6 text-white capitalize "
              id="modal-title"
            >
              Admin Dashboard
            </h3>
          </div>
        </div>
        <div
          className="w-full bg-center bg-cover h-[15rem] lg:hidden "
          style={{
            "background-image": "url('../bg.svg')",
          }}
        >
          <div className="flex items-center justify-center w-full h-full  ">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-white py-3 lg:text-7xl">
                Sign In to your Account
              </h1>
              <h1 className="text-sm lg:text-base font- text-white w-full  md:w-[28rem] ">
                Welcome back! please enter your detail
              </h1>
            </div>
          </div>
        </div>
        <div class="col-span-7 px-3 lg:px-24  h-screen py-6 lg:py-48 lg:w-[46rem] ">
          <div className="mx-auto">
            <div className="hidden lg:block">
              <h3
                className="text-2xl lg:text-4xl  font-bold leading-6 text-gray-800 capitalize "
                id="modal-title"
              >
                Forgot Password
              </h3>
              <p className="mt-2 text-sm text-[#495454] font-light ">
                Enter your email below to recieve a password reset link.
              </p>
            </div>

            <form
              className="mt-4  "
              onSubmit={handleSubmit(forgotPasswordHandler)}
            >
              <div className="flex mb-5">
                <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 7L12 13L21 7"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="email"
                  require
                  placeholder="Email"
                  {...register("email")}
                  className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
                />
              </div>

              <div className="flex flex-col space-y-5 w-full">
                <button
                  type="submit"
                  className="w-full bg-[#000] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <span className="flex items-center justify-center gap-x-1">
                      Send
                    </span>
                  )}
                </button>
              </div>
            </form>

            {/* Footer  */}
            <div className="pt-5">
              <span className="text-sm lg:text-base  text-[#36474F]">
                Remember Now?
              </span>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/");
                }}
                className="text-sm lg:text-base font-semibold text-[#FF4B00] px-1"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <div className="fixed inset-0 z-[150] overflow-y-auto bg-[#000000]/50 ">
          <div className="flex items-end justify-center sm:min-h-screen px-4 pt-12 pb-20 text-center sm:block sm:p-0">
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="relative inline-block px-4 pt-5 pb-4 overflow-hidden text-center align-bottom transition-all transform bg-white rounded-2xl shadow-xl  top-20 md:top-0 sm:my-8 w-full sm:max-w-md sm:p-6 md:p-8 sm:align-middle">
              <div className="py-4 space-y-5 justify-center">
                <img src="../Mailsent.svg" className="mx-auto w-36" alt="i" />
                <h3
                  className="text-2xl lg:text-4xl  font-bold leading-6 text-gray-800 capitalize "
                  id="modal-title"
                >
                  Recovery Mail Sent
                </h3>
                <p className="mt-2 text-sm text-[#495454] font-light ">
                  A password recovery link has been sent to your mail. click on
                  the link to recover mail.
                </p>

                <div className="flex flex-col space-y-5 w-full">
                  <button
                    onClick={() => {
                      setModal(false);
                      navigate("/login");
                    }}
                    className="w-full bg-[#CC080B] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  >
                    Open Mail
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPassword;
