import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { logout } from "../../Slices/authSlice";

function DashboardLayout() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const { userInfo } = useSelector((state) => state.auth);

  // Logout Session
  const dispatch = useDispatch();
  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate("/");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex font-Inter">
      <div
        className={`sidebar w-[17rem] hidden lg:block overflow-hidden   shadow-lg bg-[#FFF] min-h-screen );
					`}
      >
        <div className="flex flex-col justify-between px-5 pb-6">
          <div className="min-w-max  ">
            <div className="px-2 py-8">
              <img className=" w-auto" src="../logodark.png" alt="" />
            </div>
          </div>
          <div className="border-t border-[#F1F5F9]">
            <h2 className="font-semibold text-[#94A3B8] text-base px-2 py-5">
              MENU
            </h2>
            <ul className=" px-2 tracking-wide overflow-y-auto scrollbar-hide">
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/index"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-bold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-[#FFF6E1] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-[#FFF6E1] hover:text-[#FF4B00] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.25008 3.66669H4.58341C4.07715 3.66669 3.66675 4.07709 3.66675 4.58335V8.25002C3.66675 8.75628 4.07715 9.16669 4.58341 9.16669H8.25008C8.75634 9.16669 9.16675 8.75628 9.16675 8.25002V4.58335C9.16675 4.07709 8.75634 3.66669 8.25008 3.66669Z"
                      fill="currentColor"
                    />
                    <path
                      d="M17.4167 3.66669H13.7501C13.2438 3.66669 12.8334 4.07709 12.8334 4.58335V8.25002C12.8334 8.75628 13.2438 9.16669 13.7501 9.16669H17.4167C17.923 9.16669 18.3334 8.75628 18.3334 8.25002V4.58335C18.3334 4.07709 17.923 3.66669 17.4167 3.66669Z"
                      fill="currentColor"
                    />
                    <path
                      d="M8.25008 12.8334H4.58341C4.07715 12.8334 3.66675 13.2438 3.66675 13.75V17.4167C3.66675 17.9229 4.07715 18.3334 4.58341 18.3334H8.25008C8.75634 18.3334 9.16675 17.9229 9.16675 17.4167V13.75C9.16675 13.2438 8.75634 12.8334 8.25008 12.8334Z"
                      fill="currentColor"
                    />
                    <path
                      d="M17.4167 12.8334H13.7501C13.2438 12.8334 12.8334 13.2438 12.8334 13.75V17.4167C12.8334 17.9229 13.2438 18.3334 13.7501 18.3334H17.4167C17.923 18.3334 18.3334 17.9229 18.3334 17.4167V13.75C18.3334 13.2438 17.923 12.8334 17.4167 12.8334Z"
                      fill="currentColor"
                    />
                    <path
                      d="M8.25008 3.66669H4.58341C4.07715 3.66669 3.66675 4.07709 3.66675 4.58335V8.25002C3.66675 8.75628 4.07715 9.16669 4.58341 9.16669H8.25008C8.75634 9.16669 9.16675 8.75628 9.16675 8.25002V4.58335C9.16675 4.07709 8.75634 3.66669 8.25008 3.66669Z"
                      stroke="#FF4B00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4167 3.66669H13.7501C13.2438 3.66669 12.8334 4.07709 12.8334 4.58335V8.25002C12.8334 8.75628 13.2438 9.16669 13.7501 9.16669H17.4167C17.923 9.16669 18.3334 8.75628 18.3334 8.25002V4.58335C18.3334 4.07709 17.923 3.66669 17.4167 3.66669Z"
                      stroke="#FF4B00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.25008 12.8334H4.58341C4.07715 12.8334 3.66675 13.2438 3.66675 13.75V17.4167C3.66675 17.9229 4.07715 18.3334 4.58341 18.3334H8.25008C8.75634 18.3334 9.16675 17.9229 9.16675 17.4167V13.75C9.16675 13.2438 8.75634 12.8334 8.25008 12.8334Z"
                      stroke="#FF4B00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.4167 12.8334H13.7501C13.2438 12.8334 12.8334 13.2438 12.8334 13.75V17.4167C12.8334 17.9229 13.2438 18.3334 13.7501 18.3334H17.4167C17.923 18.3334 18.3334 17.9229 18.3334 17.4167V13.75C18.3334 13.2438 17.923 12.8334 17.4167 12.8334Z"
                      stroke="#FF4B00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span className="text-sm font-medium">Dashboard</span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/members"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-bold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-[#FFF6E1] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-[#FFF6E1] hover:text-[#FF4B00] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.8333 8.25H16.4999"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12.8333 11.4583H15.5833"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M15.5833 2.75H6.41658C3.88528 2.75 1.83325 4.80203 1.83325 7.33333V14.6667C1.83325 17.198 3.88528 19.25 6.41658 19.25H15.5833C18.1146 19.25 20.1666 17.198 20.1666 14.6667V7.33333C20.1666 4.80203 18.1146 2.75 15.5833 2.75Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.58325 14.6667C5.69087 12.3006 9.81944 12.145 10.9999 14.6667"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.62492 8.25002C9.62492 9.26257 8.80411 10.0834 7.79159 10.0834C6.77906 10.0834 5.95825 9.26257 5.95825 8.25002C5.95825 7.2375 6.77906 6.41669 7.79159 6.41669C8.80411 6.41669 9.62492 7.2375 9.62492 8.25002Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>

                  <span className=" text-sm font-medium">Members</span>
                </NavLink>
              </li>
              <li className="min-w-max ">
                <NavLink
                  to="/dashboard/generate"
                  className={({ isActive }) =>
                    isActive
                      ? "flex w-full items-center px-3 py-4 text-base font-bold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-[#FFF6E1] space-x-2  rounded-lg"
                      : "flex w-full items-center px-3 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-[#FFF6E1] hover:text-[#FF4B00] space-x-2 rounded-lg"
                  }
                >
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_29_625)">
                      <path
                        d="M3.5625 15.8621H12.0965C13.8969 15.8621 15.3621 14.3972 15.3621 12.5965V4.0625C15.3621 2.26183 13.8969 0.796875 12.0965 0.796875H3.5625C1.76183 0.796875 0.296875 2.26183 0.296875 4.0625V12.5965C0.296875 14.3972 1.76183 15.8621 3.5625 15.8621ZM2.07812 4.0625C2.07812 3.24406 2.74406 2.57812 3.5625 2.57812H12.0965C12.9152 2.57812 13.5809 3.24406 13.5809 4.0625V12.5965C13.5809 13.4149 12.9152 14.0809 12.0965 14.0809H3.5625C2.74406 14.0809 2.07812 13.4149 2.07812 12.5965V4.0625Z"
                        fill="currentColor"
                      />
                      <path
                        d="M17.8126 7.43884C17.3209 7.43884 16.922 7.83748 16.922 8.32947V14.75C16.922 16.2233 15.7235 17.4218 14.2501 17.4218H7.82959C7.3376 17.4218 6.93896 17.8205 6.93896 18.3125C6.93896 18.8045 7.3376 19.2031 7.82959 19.2031H14.2501C16.7057 19.2031 18.7032 17.2056 18.7032 14.75V8.32947C18.7032 7.83748 18.3043 7.43884 17.8126 7.43884Z"
                        fill="currentColor"
                      />
                      <path
                        d="M5.16748 9.22012H6.93887V10.9912C6.93887 11.4832 7.33751 11.8819 7.8295 11.8819C8.32149 11.8819 8.72012 11.4832 8.72012 10.9912V9.22012H10.4915C10.9832 9.22012 11.3821 8.82149 11.3821 8.3295C11.3821 7.83751 10.9832 7.43887 10.4915 7.43887H8.72012V5.66748C8.72012 5.17549 8.32149 4.77686 7.8295 4.77686C7.33751 4.77686 6.93887 5.17549 6.93887 5.66748V7.43887H5.16748C4.67549 7.43887 4.27686 7.83751 4.27686 8.3295C4.27686 8.82149 4.67549 9.22012 5.16748 9.22012Z"
                        fill="currentColor"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_29_625">
                        <rect
                          width="19"
                          height="19"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>

                  <span className=" text-sm font-medium">
                    Generate Access Code
                  </span>
                </NavLink>
              </li>

              {/* division Line */}
              <div className="h-[0.5px] w-full bg-[#F9FAFA]/30 my-5"></div>

              <li className="min-w-max mb-5  mt-32 ">
                <button
                  onClick={logoutHandler}
                  className="flex justify-center w-full items-center  py-4 text-base font-semibold text-[#000] capitalize transition-colors duration-300 transform  bg-[#FFF] hover:bg-[#FFF6E1] hover:text-[#FF4B00] gap-x-1 rounded-lg"
                >
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_3188_3879"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="25"
                    >
                      <rect
                        y="0.5"
                        width="24"
                        height="24"
                        fill="currentColor"
                      />
                    </mask>

                    <path
                      d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5V5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5V21.5ZM16 17.5L14.6 16.075L17.175 13.5H9V11.5H17.175L14.6 8.9L16 7.5L21 12.5L16 17.5Z"
                      fill="currentColor"
                    />
                  </svg>
                  <span className=" text-sm font-medium">Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <main
        className={`bg-[#F9FAFA] 
				 flex flex-grow flex-col  transition-all duration-150 ease-in   w-full lg:w-auto`}
      >
        <header className="lg:bg-white z-[100] bg-[#FFF]  sticky top-0">
          <nav
            className="mx-auto hidden lg:flex max-w-7xl items-center justify-between  py-4 px-6 lg:px-8  lg:gap-x-14 xl:gap-x-20"
            aria-label="Global"
          >
            {location.pathname === "/dashboard/index" && (
              <h2 className="text-xl text-black font-bold">Dashboard</h2>
            )}

            {location.pathname === "/dashboard/members" && (
              <h2 className="text-xl text-black font-bold">Users</h2>
            )}

            {location.pathname === "/dashboard/generate" && (
              <h2 className="text-xl text-black font-bold">
                Generate Access Code
              </h2>
            )}
            <div className="flex flex-1  justify-end gap-4 ">
              <div className="flex w-[22rem] lg:gap-x-1 ">
                <div className="relative w-full">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-5 pointer-events-none">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-auto pb-0.5"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                        fill="#7B7C7C"
                      />
                    </svg>
                  </div>
                  <input
                    type="text"
                    id="simple-search"
                    className="bg-[#FFF] border border-[#E2E8FF] text-gray-900 text-sm rounded-lg focus:outline-none block w-full pl-10 p-4 placeholder-bold"
                    placeholder="Search"
                    required
                  />
                </div>
              </div>
              <button className="">
                <img src="../icon.svg" alt="" />
              </button>
              <div className="flex gap-2 items-center">
                <div className="relative inline-block group">
                  {userInfo && (
                    <button
                      type="button"
                      className="flex items-center gap-x-2 text-sm font-bold leading-6  py-2.5  px-3 rounded-xl"
                      aria-expanded="false"
                    >
                      <div className="flex items-center space-x-2">
                        <div className="flex-shrink-0">
                          <img
                            className="h-8 w-8 rounded-lg"
                            src="../Avatar.svg"
                            alt=""
                          />
                        </div>
                        <div className="min-w-0">
                          <p className="text-sm font-bold text-gray-900 text-left">
                            {userInfo.admin.name}
                          </p>
                          <p className=" text-gray-500 font-light text-xs capitalize cursor-pointer">
                            {userInfo.admin.role}
                          </p>
                        </div>
                      </div>
                      <svg
                        className="h-5 w-5 flex-none text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </button>
                  )}

                  {/* <!-- Dropdown menu --> */}
                  <div className="absolute -right-2 z-[100] w-48 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl">
                    <button
                      onClick={logoutHandler}
                      className="w-full flex items-center justify-center px-4 py-2 text-sm text-red-500 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3188_3879"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="25"
                        >
                          <rect
                            y="0.5"
                            width="24"
                            height="24"
                            fill="currentColor"
                          />
                        </mask>

                        <path
                          d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5V5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5V21.5ZM16 17.5L14.6 16.075L17.175 13.5H9V11.5H17.175L14.6 8.9L16 7.5L21 12.5L16 17.5Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className=" text-sm font-medium">Logout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>

          {/* <!-- Mobile menu **************************  */}
          <div className="lg:hidden px-3 py-2 bg-[#FFF] ">
            <div className="items-center  flex justify-between ">
              <div className="flex py-5">
                {toggle ? (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(false);
                    }}
                    className="text-gray-500  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                    aria-label="toggle menu"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      stroke-width="2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(true);
                    }}
                    className="text-gray-500  hover:text-gray-600  focus:outline-none focus:text-gray-600 "
                    aria-label="toggle menu"
                  >
                    <svg
                      className="w-6 h-auto"
                      width="30"
                      height="26"
                      viewBox="0 0 30 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="20" height="6" rx="3" fill="#fff" />
                      <rect y="10" width="30" height="6" rx="3" fill="#fff" />
                      <rect y="20" width="15" height="6" rx="3" fill="#fff" />
                    </svg>
                  </button>
                )}
              </div>
              <Link href="/">
                <span className="sr-only">BETSPAE</span>
                <img className="h-9 w-auto" src="../logodark.png" alt="" />
              </Link>

              <Link
                to="##"
                className="text-sm font-semibold leading-6 text-gray-100"
                aria-expanded="false"
              >
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <mask
                    id="mask0_3188_3852"
                    maskUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="24"
                    height="25"
                  >
                    <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                  </mask>

                  <path
                    d="M4.5 19.3846V17.8846H6.3077V10.4231C6.3077 9.07818 6.72276 7.88908 7.55287 6.85575C8.38301 5.82242 9.44872 5.16153 10.75 4.87308V4.25C10.75 3.90278 10.8714 3.60765 11.1143 3.3646C11.3571 3.12153 11.6519 3 11.9988 3C12.3457 3 12.641 3.12153 12.8846 3.3646C13.1282 3.60765 13.25 3.90278 13.25 4.25V4.87308C14.5512 5.16153 15.6169 5.82242 16.4471 6.85575C17.2772 7.88908 17.6922 9.07818 17.6922 10.4231V17.8846H19.5V19.3846H4.5ZM11.9983 22.1923C11.5007 22.1923 11.0753 22.0153 10.7221 21.6613C10.3689 21.3073 10.1923 20.8817 10.1923 20.3846H13.8077C13.8077 20.8833 13.6305 21.3093 13.2761 21.6625C12.9218 22.0157 12.4959 22.1923 11.9983 22.1923ZM7.80765 17.8846H16.1923V10.4231C16.1923 9.26535 15.783 8.27721 14.9644 7.45863C14.1458 6.64003 13.1577 6.23073 12 6.23073C10.8423 6.23073 9.85413 6.64003 9.03555 7.45863C8.21695 8.27721 7.80765 9.26535 7.80765 10.4231V17.8846Z"
                    fill="currentColor"
                  />
                </svg>
              </Link>
            </div>

            <div className="flex w-full pb-3 lg:gap-x-1 ">
              <div className="relative w-full">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-auto pb-0.5"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                      fill="#7B7C7C"
                    />
                  </svg>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-8 p-3 placeholder-bold"
                  placeholder="Search"
                  required
                />
              </div>
            </div>
          </div>

          {/* toggle opaq backgroud */}
          <div
            onClick={(e) => {
              e.preventDefault();
              setToggle(false);
            }}
            className={`${
              toggle
                ? "translate-x-0 opacity-100 "
                : "opacity-0 -translate-x-full"
            } lg:hidden absolute inset-x-0 z-20 w-full top-0 h-screen transition-all duration-300 ease-in-out bg-[#FFF]/50 `}
          ></div>
          <div
            className={`${
              toggle
                ? "translate-x-0 opacity-100 "
                : "opacity-0 -translate-x-full"
            } lg:hidden absolute inset-x-0 z-20 w-[16rem] top-0 py-8 px-6 transition-all duration-300 ease-in-out bg-[#FFF]  space-y-3`}
          >
            <div className="flex flex-col justify-between  pb-14">
              <div className=" border-b border-dashed border-gray-200  w-full  mb-5 pb-6">
                <div className="relative w-full inline-block group">
                  {/* {userInfo && ( */}
                  <button
                    type="button"
                    className="flex items-center gap-x-2 text-sm font-bold leading-6  py-2.5  px-3 rounded-xl"
                    aria-expanded="false"
                  >
                    <div className="flex items-center space-x-2">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-lg"
                          // src={item.image}
                          // alt={item.full_name}
                          src="../Avatar.svg"
                          alt=""
                        />
                      </div>
                      <div className="min-w-0">
                        <p className="text-sm font-bold text-gray-100 text-left">
                          {/* {userInfo.user.firstName} {userInfo.user.lastName}{" "} */}
                          User Name
                        </p>
                        <p className=" text-gray-100 font-light text-xs capitalize cursor-pointer">
                          enterpreneur
                        </p>
                      </div>
                    </div>
                    <svg
                      className="h-5 w-5 flex-none text-gray-100"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                  {/* )} */}

                  {/* <!-- Dropdown menu --> */}
                  <div className="absolute -right-0 z-[100] w-48 p-2 hidden group-hover:block origin-top-right bg-white rounded-md shadow-xl">
                    <button
                      onClick={logoutHandler}
                      className="w-full flex items-center justify-center px-4 py-2 text-sm text-red-500 capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3188_3879"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="25"
                        >
                          <rect
                            y="0.5"
                            width="24"
                            height="24"
                            fill="currentColor"
                          />
                        </mask>

                        <path
                          d="M12 21.5C10.75 21.5 9.57917 21.2625 8.4875 20.7875C7.39583 20.3125 6.44583 19.6708 5.6375 18.8625C4.82917 18.0542 4.1875 17.1042 3.7125 16.0125C3.2375 14.9208 3 13.75 3 12.5C3 11.25 3.2375 10.0792 3.7125 8.9875C4.1875 7.89583 4.82917 6.94583 5.6375 6.1375C6.44583 5.32917 7.39583 4.6875 8.4875 4.2125C9.57917 3.7375 10.75 3.5 12 3.5V5.5C10.05 5.5 8.39583 6.17917 7.0375 7.5375C5.67917 8.89583 5 10.55 5 12.5C5 14.45 5.67917 16.1042 7.0375 17.4625C8.39583 18.8208 10.05 19.5 12 19.5V21.5ZM16 17.5L14.6 16.075L17.175 13.5H9V11.5H17.175L14.6 8.9L16 7.5L21 12.5L16 17.5Z"
                          fill="currentColor"
                        />
                      </svg>
                      <span className=" text-sm font-medium">Logout</span>
                    </button>
                  </div>
                </div>
              </div>

              <ul className=" -space-y-1 tracking-wide h-screen inset-y-0 overflow-y-auto scrollbar-hide">
                <li className="min-w-max ">
                  <NavLink
                    to="/dashboard/index"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(false);
                      navigate("/dashboard/index");
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "flex w-full items-center px-5 py-4 text-base font-semibold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-gray-100/20 space-x-4  rounded-lg"
                        : "flex w-full items-center px-5 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-gray-100/20 space-x-4 rounded-lg"
                    }
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3188_3810"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="25"
                      >
                        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                      </mask>

                      <path
                        d="M3.25 3.75H10.75V11.25H3.25V3.75ZM13.25 3.75H20.75V11.25H13.25V3.75ZM3.25 13.75H10.75V21.25H3.25V13.75ZM16.25 13.75H17.75V16.75H20.75V18.25H17.75V21.25H16.25V18.25H13.25V16.75H16.25V13.75ZM14.75 5.24995V9.75H19.25V5.24995H14.75ZM4.74995 5.24995V9.75H9.25V5.24995H4.74995ZM4.74995 15.25V19.75H9.25V15.25H4.74995Z"
                        fill="currentColor"
                      />
                    </svg>

                    <span className="text-sm font-medium">Dashboard</span>
                  </NavLink>
                </li>
                <li className="min-w-max ">
                  <NavLink
                    to="/dashboard/members"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(false);
                      navigate("/dashboard/members");
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "flex w-full items-center px-5 py-4 text-base font-semibold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-gray-100/20 space-x-4  rounded-lg"
                        : "flex w-full items-center px-5 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-gray-100/20 space-x-4 rounded-lg"
                    }
                  >
                    <svg
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_3188_4012"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="25"
                      >
                        <rect y="0.5" width="24" height="24" fill="#D9D9D9" />
                      </mask>

                      <path
                        d="M18.4423 19.227V16.4617H17.5577V19.5848L19.65 21.6771L20.2731 21.0541L18.4423 19.227ZM11.8461 4.58676L5.82495 8.06947L12 11.6387L18.175 8.06947L12.1538 4.58676C12.1025 4.55471 12.0513 4.53869 12 4.53869C11.9487 4.53869 11.8974 4.55471 11.8461 4.58676ZM3.5 16.2925V8.70792C3.5 8.37972 3.57909 8.07912 3.73727 7.80614C3.89544 7.53314 4.11763 7.31322 4.40385 7.14639L11.0961 3.29449C11.25 3.21116 11.3979 3.1493 11.5399 3.10892C11.6819 3.06853 11.8351 3.04834 11.9995 3.04834C12.1639 3.04834 12.3214 3.06853 12.4721 3.10892C12.6227 3.1493 12.7666 3.21116 12.9038 3.29449L19.5961 7.14639C19.8823 7.31322 20.1045 7.53314 20.2627 7.80614C20.4209 8.07912 20.5 8.37972 20.5 8.70792V12.4233H19V9.31174L11.975 13.3617L4.99997 9.31174V16.2771C4.99997 16.3284 5.0128 16.3765 5.03845 16.4214C5.06408 16.4663 5.10254 16.5047 5.15383 16.5368L11.3077 20.1079V21.8213L4.40385 17.854C4.11763 17.6872 3.89544 17.4673 3.73727 17.1943C3.57909 16.9213 3.5 16.6207 3.5 16.2925ZM18 23.8752C16.7513 23.8752 15.6891 23.4374 14.8135 22.5617C13.9378 21.6861 13.5 20.6239 13.5 19.3752C13.5 18.1265 13.9378 17.0643 14.8135 16.1887C15.6891 15.3131 16.7513 14.8752 18 14.8752C19.2487 14.8752 20.3109 15.3131 21.1865 16.1887C22.0621 17.0643 22.5 18.1265 22.5 19.3752C22.5 20.6239 22.0621 21.6861 21.1865 22.5617C20.3109 23.4374 19.2487 23.8752 18 23.8752Z"
                        fill="currentColor"
                      />
                    </svg>
                    <span className=" text-sm font-medium">Members</span>
                  </NavLink>
                </li>
                <li className="min-w-max ">
                  <NavLink
                    to="/dashboard/generate"
                    onClick={(e) => {
                      e.preventDefault();
                      setToggle(false);
                      navigate("/dashboard/generate");
                    }}
                    className={({ isActive }) =>
                      isActive
                        ? "flex w-full items-center px-5 py-4 text-base font-semibold text-[#FF4B00] capitalize transition-colors duration-300 transform  bg-gray-100/20 space-x-4  rounded-lg"
                        : "flex w-full items-center px-5 py-4 text-base font-semibold text-[#8F8F8F] capitalize transition-colors duration-300 transform  hover:bg-gray-100/20 space-x-4 rounded-lg"
                    }
                  >
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_29_625)">
                        <path
                          d="M3.5625 15.8621H12.0965C13.8969 15.8621 15.3621 14.3972 15.3621 12.5965V4.0625C15.3621 2.26183 13.8969 0.796875 12.0965 0.796875H3.5625C1.76183 0.796875 0.296875 2.26183 0.296875 4.0625V12.5965C0.296875 14.3972 1.76183 15.8621 3.5625 15.8621ZM2.07812 4.0625C2.07812 3.24406 2.74406 2.57812 3.5625 2.57812H12.0965C12.9152 2.57812 13.5809 3.24406 13.5809 4.0625V12.5965C13.5809 13.4149 12.9152 14.0809 12.0965 14.0809H3.5625C2.74406 14.0809 2.07812 13.4149 2.07812 12.5965V4.0625Z"
                          fill="currentColor"
                        />
                        <path
                          d="M17.8126 7.43884C17.3209 7.43884 16.922 7.83748 16.922 8.32947V14.75C16.922 16.2233 15.7235 17.4218 14.2501 17.4218H7.82959C7.3376 17.4218 6.93896 17.8205 6.93896 18.3125C6.93896 18.8045 7.3376 19.2031 7.82959 19.2031H14.2501C16.7057 19.2031 18.7032 17.2056 18.7032 14.75V8.32947C18.7032 7.83748 18.3043 7.43884 17.8126 7.43884Z"
                          fill="currentColor"
                        />
                        <path
                          d="M5.16748 9.22012H6.93887V10.9912C6.93887 11.4832 7.33751 11.8819 7.8295 11.8819C8.32149 11.8819 8.72012 11.4832 8.72012 10.9912V9.22012H10.4915C10.9832 9.22012 11.3821 8.82149 11.3821 8.3295C11.3821 7.83751 10.9832 7.43887 10.4915 7.43887H8.72012V5.66748C8.72012 5.17549 8.32149 4.77686 7.8295 4.77686C7.33751 4.77686 6.93887 5.17549 6.93887 5.66748V7.43887H5.16748C4.67549 7.43887 4.27686 7.83751 4.27686 8.3295C4.27686 8.82149 4.67549 9.22012 5.16748 9.22012Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_29_625">
                          <rect
                            width="19"
                            height="19"
                            fill="white"
                            transform="translate(0 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>

                    <span className=" text-sm font-medium">
                      Generate Access Code
                    </span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </header>
        <Outlet />
      </main>
    </div>
  );
}

export default DashboardLayout;
