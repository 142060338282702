import React from "react";
import { Link } from "react-router-dom";

function Members() {
  return (
    <section className="container p-10  mx-auto">
      <div className="flex bg-[#fff] items-center rounded-lg">
        <div className="flex justify-between  w-full p-3 lg:gap-x-8 ">
          <div className="w-full flex items-center justify-between">
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-auto pb-0.5"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2 9C2 5.13401 5.13401 2 9 2C12.866 2 16 5.13401 16 9C16 10.8866 15.2537 12.5988 14.0402 13.8576C14.0064 13.8837 13.9738 13.9123 13.9428 13.9433C13.9118 13.9743 13.8833 14.0068 13.8572 14.0406C12.5984 15.2539 10.8863 16 9 16C5.13401 16 2 12.866 2 9ZM14.6174 16.0321C13.0778 17.2636 11.1249 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 11.1251 17.2634 13.0782 16.0317 14.618L19.707 18.2933C20.0975 18.6838 20.0975 19.317 19.707 19.7075C19.3165 20.098 18.6833 20.098 18.2928 19.7075L14.6174 16.0321Z"
                    fill="#7B7C7C"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-8 p-3 placeholder-bold"
                placeholder="Search User Name"
                required
              />
            </div>
            <div className="border-r h-6"></div>
          </div>

          <div className="w-full flex items-center justify-between">
            <div className="relative w-full">
              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-auto"
                >
                  <path
                    d="M19.9092 10.4479C19.9092 16.4934 11.9092 21.6752 11.9092 21.6752C11.9092 21.6752 3.90918 16.4934 3.90918 10.4479C3.90918 8.38644 4.75203 6.40942 6.25233 4.95175C7.75262 3.49408 9.78745 2.67517 11.9092 2.67517C14.0309 2.67517 16.0657 3.49408 17.566 4.95175C19.0663 6.40942 19.9092 8.38644 19.9092 10.4479Z"
                    stroke="#94A3B8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 12.6752C13.3807 12.6752 14.5 11.5559 14.5 10.1752C14.5 8.79446 13.3807 7.67517 12 7.67517C10.6193 7.67517 9.5 8.79446 9.5 10.1752C9.5 11.5559 10.6193 12.6752 12 12.6752Z"
                    stroke="#94A3B8"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-white text-gray-900 text-sm rounded-2xl focus:outline-none block w-full pl-9 p-3 placeholder-bold"
                placeholder="Select Location"
                required
              />
            </div>
            <div className="border-r h-6"></div>
          </div>

          <div className="w-full flex items-center justify-between">
            <button className="">
              <img src="../filter.svg" alt="" />
            </button>
            <button className="bg-[#FF4B00] text-white py-2 px-12 rounded-lg">
              Search
            </button>
          </div>
        </div>
      </div>
      <div className="py-7 flex gap-x-3 text-sm font-bold text-[#111827] whitespace-nowrap ">
        <p className="text-[#B0B7C3] font-normal">Showing:</p>
        <h1> 60 filtered users</h1>
      </div>
      <div className="flex flex-col bg-[#F9F9F9]  rounded-lg">
        <div className=" overflow-x-auto  h-[36rem] scrollbar-hide bg-[#FFF] rounded-2xl">
          <div className="inline-block min-w-full p-9 align-middle ">
            <div className="overflow-hidden md:rounded-lg ">
              <div className="min-w-full ">
                {/* {isLoading ? (
                    <div className="pb-5">
                      <PageLoading />
                    </div>
                  ) : error ? (
                    <div className="pt-5">
                      <Loading />
                    </div>
                  ) : ( */}
                <>
                  {/* {filteredAccounts.map((item) => ( */}
                  <div
                    // key={item.id}
                    className="my-2 rounded-lg flex items-center justify-between "
                  >
                    <div className="flex gap-x-5">
                      <img src="../avatar.svg" alt="" />

                      <div className="px-3 py-5 text-sm font-bold text-[#111827] whitespace-nowrap ">
                        <h1> full_name</h1>
                        <p className="text-[#B0B7C3] font-normal">
                          joined 2 days ago
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-28">
                      <div className="px-4 py-2 rounded-lg  flex gap-x-1 bg-[#DBEFFF] text-sm text-[#4D98D2] font-medium whitespace-nowrap">
                        <img src="../checke.svg" alt="" />
                        Verified
                      </div>
                      <div className="px-3 text-sm text-[#fff] whitespace-nowrap ">
                        <div className="flex items-center justify-end">
                          <Link
                            // to={`members/${item.id}`}
                            className="bg-[#FF4B00] py-2 px-8 rounded-lg"
                          >
                            View Profile
                          </Link>
                          <div className=" inline-block group ml-2">
                            <button
                              type="submit"
                              className="px-0.5 ml-1 text-xs font-medium text-white   rounded-md focus:outline-none "
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
                                  stroke="#94A3B8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
                                  stroke="#94A3B8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z"
                                  stroke="#94A3B8"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                            {/* <!-- Dropdown menu --> */}
                            <div className="absolute  z-[100] w-32 p-2 hidden group-hover:block right-10 bg-[#FFFFFF] rounded-md shadow-xl ">
                              <button
                                // onClick={() => {
                                //   setUserId(item.id);
                                //   setDeleteModal(true);
                                // }}
                                className="w-full text-left block px-4 py-2 text-xs text-[#CA0D0D] capitalize transition-colors duration-300 transform hover:bg-gray-100  rounded"
                              >
                                Delete User
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ))} */}
                </>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Members;
