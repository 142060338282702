import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { setCredentials } from "../../Slices/authSlice";
import { useLoginMutation } from "../../Slices/apisSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import SEO from "../../components/SEO";
import Spinner from "../../components/preLoaders/Spinner";

function Login() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [passwordType, setPasswordType] = useState("password");
  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();

  const { userInfo } = useSelector((state) => state.auth);
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const loginHandler = async (data) => {
    try {
      const res = await login(data).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate("dashboard/index");
    } catch (err) {
      toast.error(err?.data?.msg || err.error);
    }
  };

  const { search } = useLocation();
  const redirectInUrl = new URLSearchParams(search).get("redirect");
  const redirect = redirectInUrl ? redirectInUrl : "/dashboard/index";

  useEffect(() => {
    if (userInfo) {
      navigate(redirect);
    }
  }, [navigate, redirect, userInfo]);

  return (
    <>
      <SEO
        title="Login"
        description="EnterpriseBuzz is a pioneering media and technology company, dedicated to propelling businesses forward through innovative advertising, branding, CRM, and marketing tools."
        name="EnterpriceBuzz"
        type="description"
      />
      <div class="lg:grid grid-cols-12 gap-x-5 font-Lato">
        <div
          class="col-span-5 hidden bg-cover lg:block pt-40"
          style={{
            "background-image": "url('../bg.svg')",
          }}
        >
          <div className="w-[19.5rem] mx-auto text-center flex flex-col align-middle justify-center py-40">
            <img src="./logo.svg" className="w-full" alt="i" />
            <h3
              className="text-base lg:text-xl  font-extralight  py-5 leading-6 text-white capitalize "
              id="modal-title"
            >
              Admin Dashboard
            </h3>
          </div>
        </div>
        <div
          className="w-full bg-center bg-cover h-[15rem] lg:hidden "
          style={{
            "background-image": "url('../bg.svg')",
          }}
        >
          <div className="flex items-center justify-center w-full h-full  ">
            <div className="text-center">
              <h1 className="text-2xl font-semibold text-white py-3 lg:text-7xl">
                Sign In to your Account
              </h1>
              <h1 className="text-sm lg:text-base font- text-white w-full  md:w-[28rem] ">
                Welcome back! please enter your detail
              </h1>
            </div>
          </div>
        </div>
        <div class="col-span-7 px-3 lg:px-24  h-screen py-6 lg:py-40 lg:w-[40rem] mx-auto">
          <div className="mx-auto">
            <div className="hidden lg:block">
              <h3
                className="text-2xl   font-bold leading-6 text-gray-800 capitalize "
                id="modal-title"
              >
                Sign In to your Account
              </h3>
              <p className="mt-2 text-sm text-[#64748B] font-light ">
                Welcome back! please enter your detail
              </p>
            </div>

            <form className="mt-10  " onSubmit={handleSubmit(loginHandler)}>
              <div className="flex mb-5">
                <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 5H5C3.89543 5 3 5.89543 3 7V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5Z"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3 7L12 13L21 7"
                      stroke="#64748B"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <input
                  type="email"
                  require
                  placeholder="Email"
                  {...register("email")}
                  className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
                />
              </div>

              <div className="mb-5">
                <div className="flex -mr-px">
                  <span className="relative -mr-12  flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17 11H7C5.89543 11 5 11.8954 5 13V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V13C19 11.8954 18.1046 11 17 11Z"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17Z"
                        stroke="#64748B"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 11V7C8 5.93913 8.42143 4.92172 9.17157 4.17157C9.92172 3.42143 10.9391 3 12 3C13.0609 3 14.0783 3.42143 14.8284 4.17157C15.5786 4.92172 16 5.93913 16 7V11"
                        stroke="#64748B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <input
                    required
                    {...register("password")}
                    type={passwordType}
                    placeholder="Password"
                    className="py-3 px-10 border-[0.5px] bg-[#FFF] border-[#94A5AB] rounded-lg w-full focus:outline-none placeholder:text-[#94A3B8] placeholder:font-light"
                  />
                  <span className="relative -ml-12 sm:-ml-16 flex items-center px-3 leading-normal text-gray-600 whitespace-no-wrap border-0 rounded rounded-l-none">
                    <button
                      type="button"
                      onClick={() => {
                        if (passwordType === "password") {
                          setPasswordType("text");
                          return;
                        }
                        setPasswordType("password");
                      }}
                      className="flex justify-center w-4 sm:w-full py-1 text-base text-gray-500 transition duration-200 ease-in-out rounded cursor-pointer hover:scale-110 focus:outline-none"
                    >
                      {passwordType === "password" ? (
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.2556 2.74408C17.5811 3.06951 17.5811 3.59715 17.2556 3.92259L3.9223 17.2559C3.59687 17.5814 3.06923 17.5814 2.74379 17.2559C2.41836 16.9305 2.41836 16.4028 2.74379 16.0774L16.0771 2.74408C16.4026 2.41864 16.9302 2.41864 17.2556 2.74408Z"
                            fill="black"
                          />
                          <path
                            d="M13.166 4.4767C12.2274 4.03469 11.1691 3.75 9.9997 3.75C7.54629 3.75 5.58202 5.00308 4.18604 6.33307C2.78685 7.66611 1.87897 9.14973 1.51958 9.7915C1.28406 10.2121 1.25601 10.7143 1.44934 11.1603C1.58435 11.4718 1.81395 11.9544 2.15237 12.5143C2.39041 12.9082 2.9027 13.0346 3.29659 12.7965C3.69049 12.5585 3.81683 12.0462 3.57879 11.6523C3.3066 11.2019 3.11772 10.8124 3.00316 10.5537C3.34129 9.95778 4.14208 8.67693 5.33569 7.53975C6.56858 6.36513 8.14141 5.41667 9.9997 5.41667C10.6681 5.41667 11.2995 5.53937 11.8915 5.75116L13.166 4.4767Z"
                            fill="black"
                          />
                          <path
                            d="M14.7418 7.61491C15.8923 8.73288 16.6655 9.97087 16.9962 10.5537C16.8817 10.8124 16.6928 11.2019 16.4206 11.6523C16.1826 12.0462 16.3089 12.5585 16.7028 12.7965C17.0967 13.0346 17.609 12.9082 17.847 12.5143C18.1855 11.9544 18.4151 11.4718 18.5501 11.1603C18.7434 10.7143 18.7153 10.2121 18.4798 9.7915C18.1297 9.16625 17.2589 7.74193 15.9204 6.43629L14.7418 7.61491Z"
                            fill="black"
                          />
                          <path
                            d="M9.99971 6.66667C10.3026 6.66667 10.5979 6.69898 10.8824 6.76034L9.1675 8.47519C8.45626 8.7262 7.89258 9.28987 7.64157 10.0011L5.92672 11.716C5.86536 11.4315 5.83305 11.1362 5.83305 10.8333C5.83305 8.53215 7.69853 6.66667 9.99971 6.66667Z"
                            fill="black"
                          />
                          <path
                            d="M9.99971 13.3333C9.70732 13.3333 9.42665 13.2831 9.16585 13.1909L7.91498 14.4418C8.52815 14.7968 9.2402 15 9.99971 15C12.3009 15 14.1664 13.1345 14.1664 10.8333C14.1664 10.0738 13.9632 9.36177 13.6081 8.74859L12.3573 9.99947C12.4495 10.2603 12.4997 10.5409 12.4997 10.8333C12.4997 12.214 11.3804 13.3333 9.99971 13.3333Z"
                            fill="black"
                          />
                        </svg>
                      ) : (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 6.5C13.8387 6.49389 15.6419 7.00678 17.2021 7.97973C18.7624 8.95267 20.0164 10.3462 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C3.98362 10.3462 5.23763 8.95267 6.79788 7.97973C8.35813 7.00678 10.1613 6.49389 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C12.663 9.5 13.2989 9.76339 13.7678 10.2322C14.2366 10.7011 14.5 11.337 14.5 12C14.5 12.663 14.2366 13.2989 13.7678 13.7678C13.2989 14.2366 12.663 14.5 12 14.5C11.337 14.5 10.7011 14.2366 10.2322 13.7678C9.76339 13.2989 9.5 12.663 9.5 12C9.5 11.337 9.76339 10.7011 10.2322 10.2322C10.7011 9.76339 11.337 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
                            fill="black"
                          />
                        </svg>
                      )}
                    </button>
                  </span>
                </div>
              </div>

              <div className="flex flex-col space-y-5 w-full">
                <button
                  type="submit"
                  value="submit"
                  className="w-full bg-[#000] rounded-lg p-3 text-white font-semibold transition duration-200 hover:bg-[#757E7E]"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <span className="flex items-center justify-center gap-x-1">
                      Sign In
                    </span>
                  )}
                </button>
              </div>
              <div className="my-10 flex gap-x-1 justify-center items-center">
                <p className="text-sm text-[#1a1a1a] font-light ">
                  Forgot password?
                </p>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/forgotpassword");
                  }}
                  className="text-xs font-semibold text-[#FF4B00]"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
