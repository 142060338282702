import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/authPages/Login";
import ForgotPassword from "./pages/authPages/ForgotPassword";
import ResetPassword from "./pages/authPages/ResetPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardLayout from "./components/layouts/DashboardLayout";
import Index from "./pages/dashboardScreens/Index";
import Generate from "./pages/dashboardScreens/Generate";
import Members from "./pages/dashboardScreens/Members";
import UserDetails from "./pages/dashboardScreens/UserDetails";

function App() {
  return (
    <div className="font-inter">
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />

          {/* <Route path="" element={<AuthRoute />}> */}
          <Route path="dashboard" element={<DashboardLayout />}>
            <Route path="index" element={<Index />} />
            <Route path="generate" element={<Generate />} />
            <Route path="members" element={<Members />} />
            <Route path="members/:id" element={<UserDetails />} />
          </Route>

          {/* </Route> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
